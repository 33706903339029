export const formatDate = (date: string, lang: string): string => {
  return new Date(date).toLocaleString(lang, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
};

export const getHour = (date, lang) => {
  return new Date(date).toLocaleString(lang, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export function generateArrayOfYears(num = 10) {
  var max = new Date().getFullYear();
  var min = max - num;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function generateArrayFromYears(num = 2010) {
  var max = new Date().getFullYear();
  var years = [];

  for (var i = max; i >= num; i--) {
    years.push(i);
  }
  return years;
}
