import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
// import CustomButton from '@atoms/CustomButton/CustomButton'
import { useSocials } from '@hooks/useSocials';
import { useColors } from '@hooks/useColors';
// import useCtaVariant from '@organisms/IntroParagraph/useCtaVariant';
import { getFieldLink } from '@utils/functions/getFieldLink';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import { formatDate } from '@utils/functions/date';
import { IntroModel } from '@model/intro.model';
import { IntroParagraphProps } from '@organisms/IntroParagraph/IntroParagraph.props';
import styles from './IntroParagraph.module.scss';

// const Typography = dynamic(() => import('@mui/material/Typography'), {ssr: false});
const Box = dynamic(() => import('@mui/material/Box'));
const Grid = dynamic(() => import('@mui/material/Grid'));
const ArrowForwardIcon = dynamic(() => import('@mui/icons-material/ArrowForward'));
const CustomButton = dynamic(() => import('@atoms/CustomButton/CustomButton'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const FadeIn = dynamic(() => import('@atoms/FadeIn'));
const BackgroundTransitionBox = dynamic(() => import('@atoms/BackgroundTransitionBox/BackgroundTransitionBox'));
const ContentLayoutWrapper = dynamic(() => import('@organisms/IntroParagraph/ContentLayoutWrapper'));
const IntroInfos = dynamic(() => import('@molecules/IntroInfos/IntroInfos'));
const RoundedTextVector = dynamic(() => import('@atoms/CustomVectors/RoundedTextVector'));
const BubbleIntroVector = dynamic(() => import('@atoms/CustomVectors/BubbleIntroVector'));
const RichText = dynamic(() => import('@organisms/RichTextParagraph/RichText'));

export default function IntroParagraph(props: IntroParagraphProps) {
  const {
    fieldParagraph,
    isTransitionBoxDisabled = false,
    isBackgroundColorDisabled = false,
    removePadding,
    mockDataUrl,
    dataOverride,
    showBg = false,
    showCategory = false,
    node,
  } = props;

  const router = useRouter();

  // const {
  //   mappedData: { data, isLoaded },
  // } = useMapper<any, IntroModel>({
  //   backendData: fieldParagraph,
  //   mockDataUrl: mockDataUrl || fieldParagraph.mockDataUrl || '/data/intro-dark-center.json',
  //   mockDataMapper: (setData, mockData) => {
  //     setData({ ...mockData, ...dataOverride });
  //   },
  //   realDataMapper: (setData, backendData) => {
  //     setData({
  //       anchorData: backendData.behavior_settings?.style_options?.toc?.label || '',
  //       backgroundImage: {
  //         imageAlt: backendData.field_media?.field_media_image?.resourceIdObjMeta?.alt || '',
  //         imageUrl: absoluteUrl(backendData.field_media?.field_media_image?.uri?.url),
  //       },
  //       bubbleStyle: backendData.behavior_settings?.style_options?.bubble_styles?.css_class || '',
  //       ...(showCategory ? { category: backendData?.field_marquee } : {}),
  //       colorVariant: backendData.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
  //       cta: getFieldLink(backendData.field_link || backendData.field_cta),
  //       ctaVariant:
  //         backendData.field_link?.options.attributes?.preset_class ||
  //         backendData.field_cta?.options.attributes?.preset_class ||
  //         'primary',
  //       description: backendData.field_description?.processed || '',
  //       direction: backendData.behavior_settings?.style_options?.vertical_lines?.css_class,
  //       fieldIntroLayout: backendData.field_intro_layout,
  //       hasIntroIcon: backendData.field_intro_icon,
  //       id: backendData.id,
  //       isFullImageBackground:
  //         backendData.behavior_settings?.style_options?.intro_configs?.css_class?.['bg-fullscreen'],
  //       logoStyle: backendData.behavior_settings?.style_options?.logo_styles?.css_class || '',
  //       overline: backendData.field_overline,
  //       showShareButton: backendData.behavior_settings?.style_options?.intro_configs?.css_class?.sharable,
  //       tags: backendData?.field_tags?.map((tag) => tag.name),
  //       timeToRead: backendData?.field_number,
  //       title: backendData.field_title,
  //       transition: backendData.behavior_settings?.style_options?.transition?.css_class,
  //       titleSize: backendData.field_title_size || 'h2',
  //       ...dataOverride,
  //     });
  //   },
  // });

  const isMedia = node.type === 'node--article' && router.asPath?.startsWith('/media/');

  const data = useMemo(() => {
    const paragraphIndex = node.field_paragraphs.findIndex((paragraph) => paragraph.id === fieldParagraph.id);
    const isFirstParagraph = paragraphIndex === 0;
    const newData: IntroModel = {
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
      backgroundImage: {
        imageAlt: fieldParagraph.field_media?.field_media_image?.resourceIdObjMeta?.alt || '',
        imageUrl: absoluteUrl(fieldParagraph.field_media?.field_media_image?.uri?.url),
      },
      bubbleStyle: fieldParagraph.behavior_settings?.style_options?.bubble_styles?.css_class || '',
      ...(showCategory ? { category: fieldParagraph?.field_marquee } : {}),
      colorVariant: fieldParagraph.behavior_settings?.style_options?.color_scheme?.css_class || 'light',
      cta: getFieldLink(fieldParagraph.field_link || fieldParagraph.field_cta),
      ctaVariant:
        fieldParagraph.field_link?.options.attributes?.preset_class ||
        fieldParagraph.field_cta?.options.attributes?.preset_class ||
        'primary',
      description: fieldParagraph.field_description?.processed || '',
      direction: fieldParagraph.behavior_settings?.style_options?.vertical_lines?.css_class,
      fieldIntroLayout: fieldParagraph.field_intro_layout,
      hasIntroIcon: fieldParagraph.field_intro_icon,
      id: fieldParagraph.id,
      isFullImageBackground:
        fieldParagraph.behavior_settings?.style_options?.intro_configs?.css_class?.['bg-fullscreen'],
      logoStyle: fieldParagraph.behavior_settings?.style_options?.logo_styles?.css_class || '',
      overline: fieldParagraph.field_overline,
      showShareButton: fieldParagraph.behavior_settings?.style_options?.intro_configs?.css_class?.sharable,
      tags: fieldParagraph?.field_tags?.map((tag) => tag.name),
      timeToRead: fieldParagraph?.field_number,
      title: fieldParagraph.field_title,
      transition: fieldParagraph.behavior_settings?.style_options?.transition?.css_class,
      titleSize: isFirstParagraph && node.type != 'node--article' ? 'h1' : fieldParagraph.field_title_size || 'h2',
      ...dataOverride,
    };

    return newData;
  }, [fieldParagraph]);

  const socials = useSocials({
    title: node?.title,
    url: node?.path ? absoluteUrl(node.path?.alias) : '/',
  });

  const { textColor } = useColors(data?.colorVariant);

  const textAlign = useMemo(() => {
    if (!data) return 'left';

    if (!data.fieldIntroLayout) return 'center';

    return data.fieldIntroLayout === 'center' ? 'center' : 'left';
  }, [data]);

  let ctaVariant;
  useEffect(() => {
    const isHome = document.body.classList.contains('page-home');

    ctaVariant = isHome
      ? 'secondaryOnDark'
      : data.ctaVariant === 'primary'
      ? data.colorVariant === 'dark' || (showBg && data?.backgroundImage?.imageUrl)
        ? 'primaryOnDark'
        : 'primary'
      : data.ctaVariant === 'secondary'
      ? data.colorVariant === 'dark' || (showBg && data?.backgroundImage?.imageUrl)
        ? 'secondaryOnDark'
        : 'secondary'
      : data.colorVariant === 'dark' || (showBg && data?.backgroundImage?.imageUrl)
      ? 'tertiaryOnDark'
      : 'tertiary';
  }, []);

  if (!data) return null;

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(data.anchorData || '')}
      isTransitionBoxDisabled={isTransitionBoxDisabled}
      isBackgroundColorDisabled={isBackgroundColorDisabled}
      transition={data.transition}
      colorVariant={data.colorVariant}
      linesDirection={data.direction}
    >
      <FadeIn>
        <Box
          {...(removePadding
            ? {}
            : {
                sx: {
                  pt: { lg: theme.spacing(104), xs: theme.spacing(80) },
                  pb: { lg: theme.spacing(64), xs: theme.spacing(40) },
                  overflow: 'visible',
                  overflowX: 'hidden',
                },
              })}
          position="relative"
        >
          {!!data.bubbleStyle && (
            <BubbleIntroVector
              sxOverride={{
                position: 'absolute',
                ...(data.bubbleStyle === 'bubble-left'
                  ? { top: 0, left: theme.spacing(-90) }
                  : {
                      bottom: 0,
                      right: theme.spacing(-90),
                    }),
              }}
            />
          )}
          <GridContainer
            className={`${styles.IntroParagraph} ${
              showBg && !!data?.backgroundImage?.imageUrl && data?.isFullImageBackground ? styles.hasBackground : ''
            }`}
            style={{ position: 'relative' }}
          >
            {showBg && !!data?.backgroundImage?.imageUrl && data?.isFullImageBackground && (
              <Image
                src={data.backgroundImage.imageUrl}
                alt={data.backgroundImage.imageAlt}
                fill
                className={styles.image}
                style={{ objectFit: 'cover' }}
              />
            )}
            <Box
              className={`${
                showBg && !!data?.backgroundImage?.imageUrl && !data?.isFullImageBackground ? styles.hasBackground : ''
              }`}
              position="relative"
            >
              <Row>
                {showBg && !!data?.backgroundImage?.imageUrl && !data?.isFullImageBackground && (
                  <Image
                    src={data.backgroundImage.imageUrl}
                    alt={data.backgroundImage.imageAlt}
                    fill
                    className={styles.image}
                  />
                )}
                <Grid item xs={12} position="relative">
                  <Row textAlign={textAlign}>
                    <ContentLayoutWrapper {...data}>
                      {!!data.logoStyle && (
                        <RoundedTextVector
                          sxOverride={{
                            position: 'absolute',
                            top: { xs: theme.spacing(-56), lg: theme.spacing(-80) },
                            ...(data.logoStyle === 'logo-left' ? { left: { xs: 8, md: 16, xl: 24 } } : { right: 0 }),
                          }}
                        />
                      )}

                      {data.overline && (
                        <Typography
                          variant="overlineMono"
                          component="p"
                          color={showBg && data?.backgroundImage?.imageUrl ? 'common.white' : textColor}
                          mb={{ xs: 24, lg: 16 }}
                        >
                          {data.overline}
                        </Typography>
                      )}

                      {isMedia && !data.overline && (
                        <Typography
                          variant="overlineMono"
                          component="p"
                          color={showBg && data?.backgroundImage?.imageUrl ? 'common.white' : textColor}
                          mb={{ xs: 24, lg: 16 }}
                        >
                          {formatDate(node.field_date, node.langcode)}
                        </Typography>
                      )}

                      {data.title && (
                        <Typography
                          variant={data.titleSize}
                          component={data.titleSize}
                          color={showBg && data?.backgroundImage?.imageUrl ? 'common.white' : textColor}
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        />
                      )}

                      {data.description && (
                        <Typography
                          mt={24}
                          variant="subtitle"
                          color={showBg && data?.backgroundImage?.imageUrl ? 'common.white' : textColor}
                          component="div"
                          sx={{
                            'p.MuiTypography-root.MuiTypography-bodyL': {
                              letterSpacing: 'auto',
                              fontSize: '1.125rem',
                              lineHeight: '1.6875rem',
                            },
                            '@media (min-width: 576px)': {
                              '.p.MuiTypography-root.MuiTypography-bodyL': {
                                fontSize: '1.25rem',
                                lineHeight: '1.875rem',
                              },
                            },
                            '@media (min-width: 992px)': {
                              'p.MuiTypography-root.MuiTypography-bodyL': {
                                fontSize: '1.375rem',
                                lineHeight: '2.0625rem',
                              },
                            },
                          }}
                        >
                          <RichText text={data.description} />
                        </Typography>
                      )}

                      {data.cta && (
                        <CustomButton
                          id={data.id}
                          sx={{ mt: { xs: 24, lg: 32 } }}
                          variant="large"
                          color={ctaVariant}
                          endIcon={data.ctaVariant === 'tertiary' ? <ArrowForwardIcon /> : ''}
                          external={data.cta.isExternal}
                          target={data.cta.target}
                          text={data.cta.label}
                          href={data.cta.url}
                        />
                      )}
                    </ContentLayoutWrapper>
                  </Row>
                </Grid>
              </Row>
            </Box>

            <IntroInfos
              socials={socials}
              timeToRead={data.timeToRead}
              showShareButton={data.showShareButton}
              tags={data.tags}
              category={data.category}
            />
          </GridContainer>
        </Box>
      </FadeIn>
    </BackgroundTransitionBox>
  );
}
